.text-hover-button {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  background-color: #17171700;
  border: none;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.text-hover-button span:not(:nth-child(6)) {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  width: 30px;
  background-color: #f96c96;
  border-radius: 50%;
  transition: 0.6s ease;
}

.text-hover-button span:nth-child(6) {
  position: relative;
}

.text-hover-button span:nth-child(1) {
  transform: translate(-3.3em, -4em);
}

.text-hover-button span:nth-child(2) {
  transform: translate(-6em, 1.3em);
}

.text-hover-button span:nth-child(3) {
  transform: translate(-0.2em, 1.8em);
}

.text-hover-button span:nth-child(4) {
  transform: translate(3.5em, 1.4em);
}

.text-hover-button span:nth-child(5) {
  transform: translate(3.5em, -3.8em);
}

.text-hover-button:hover span:not(:nth-child(6)) {
  transform: translate(-50%, -50%) scale(4);
  transition: 1.5s ease;
}
