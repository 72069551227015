.banner {
    background: linear-gradient(to right, #070707, #176876, #F76A95);
}
.banner {
    background: linear-gradient(to right, #070707, #176876, #F76A95);
    background-size: 200% 100%;
    animation: waveAnimation 8.5s linear infinite;
  }

  .transparent-background {
    background-color: rgba(0, 0, 0, 0.5); /* Using rgba() */
    /* OR */
    background-color: transparent; /* Using the transparent keyword */
  }
  
  .textwhite{
    color: wheat;
  }
  
  .logo{
    margin-left: 30%;
  }


  .margin_tenpx{
    margin: 10px;
  }

  .mpzero{
    margin: 0;
    padding: 0;
  }
  
  @keyframes waveAnimation {
    0% { background-position: 0 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0 50%; }
  }