.container_scroll {
    /* height: 50vh; */
    overflow-y: scroll;
    align-items: center;
    /* justify-content: center; */
    overflow-x: hidden;
    /* justify-content: center; */


  }
  
  .image {
    padding-left: 0;
    margin: 0;
    opacity: 0;
    width: 80%;
    transform: translateY(5em);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  .image.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  @media only screen and (max-width: 640px) {
    .image {
      margin-top: 0;
      padding-top: 0;
      width: 100%;
    }
  }
  