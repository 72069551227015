.graph {
    padding-left: 25%;
  }
  
.margin{
    margin-bottom: 0;
    padding-bottom: 0;
}
  .graph::-webkit-scrollbar {
    width: 0;
  }
  
  .graph {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  @media only screen and (max-width: 640px) {
    .graph {
      padding-left: 20%;
    }
    
    .margin{
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 0.8em;
  }

  }
  
  
  
  
  