.textdesign{
  font-family: 'Raleway', sans-serif;
  color: #094651;
}
.bannner {
  background-color: #FDE9EB;
}

.hover-one-box {
  position: relative;
  width: 200px;
  height: 270px;
  background: linear-gradient(to right, rgba(23, 104, 118, 0.5), rgba(247, 106, 149, 0.5));
  box-shadow: 0 30px 30px rgba(0, 0, 0.5);
}

.hover-one-box .hover-one-imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hover-one-box .hover-one-imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}

.hover-one-box:hover .hover-one-imgBx img {
  opacity: 0;
}

.hover-one-box .hover-one-content {
  position: absolute;
  width: 1px;
  height: 1px;
  transition: 0.5s;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0px;
}

.hover-one-box:hover .hover-one-content {
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}

.hover-one-box .hover-one-content h3 {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.hover-one-box .hover-one-content p {
  padding: 0;
  opacity: 0;
  line-height: 1.2em;
  transition: 0.5s;
  text-align: left;
}

.hover-one-box:hover .hover-one-content p {
  opacity: 1;
  transition-delay: 0.5s;
}
