@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

.contactformopicontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.contactformopiimage {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 50%;
}

.contactformopiimage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.contactformopiform-container {
  width: 100%;
}

.form {
  padding: 0;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  font-family: 'Playfair Display', serif;
}

.form_title {
  margin-top: 0em;
  font-size: 1.6em;
  font-style: bold;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

input,
textarea {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1.9em;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Media Queries */
@media only screen and (max-width: 480px) {
  .form {
    max-width: 100%;
    padding: 1rem;
  }

  .contactformopicontainer {
    flex-direction: column;
    align-items: center;
  }

  .contactformopiimage {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 768px) {
  .form {
    max-width: 100%;
    padding: 1rem;
  }

  .contactformopiimage {
    width: 180px;
    height: 180px;
  }
}
