.textfont{
    font-family: 'Raleway', sans-serif;
}
.banner {
    background: linear-gradient(to right, #070707, #176876, #F76A95);
}

.hover-card-container {
    padding-bottom: 20px;
  }
  