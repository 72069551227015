@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

:root {
  --backgroundColor: #rgb(30 41 59 / var(1));
  --primaryColor: #00457e;
  --primaryTextColor: #806491;

  --headlineBig: clamp(5rem, 9.9vw, 15rem);
  --headlineMedium: clamp(1rem, 2vw, 4rem);
  --headlineSmall: clamp(0.6rem, 0.75vw, 1.3rem);
  --spacing: clamp(0.9rem, 0.75vw, 1.3rem);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
